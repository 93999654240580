

import { DesktopPoster } from './ui/DesktopPoster';
import { StickyBranding } from './ui/StickyBranding';

function App() {
  return (
    <div className="md:grid md:grid-cols-2">
      <StickyBranding />
      <div className="flex min-h-[100dvh] items-center justify-center p-4">
        <div className="w-[300px]">
          <div className='text-white flex justify-center items-center'>
            <button 
             onClick={() => {
              window.location.href = 'https://nhwm.co.in/'
             }}
            className=' bg-[rgb(112,73,201)]/80 inline-flex items-center justify-center whitespace-nowrap rounded-md font-semibold transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 px-2 py-3 w-full h-12 text-lg text-white hover:shadow-md hover:shadow-slate-400 hover:bg-[#6f49c9bc]'>Click Here for Candidate Login</button>
          </div>
        </div>
      </div>
      <div className="hidden md:block">
        <DesktopPoster />
      </div>
    </div>
  );
}

export default App;
