import brandlogo from '../nhwm.png';

export const BrandLogo = ({ className }) => {
  return (
    <img
      src={brandlogo}
      alt="National Healthness and Wellness Mission"
      className={className}
    />
  );
};
